import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { RichText } from "prismic-reactjs";

import PageHeader from "../components/page-header";
import { Wrapper } from "../components/layout-components";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function PageTemplate({
  data: {
    prismicPage: {
      data: { title, body },
    },
  },
}) {
  return (
    <>
      <PageHeader title={title} />
      <Grid>{body.raw && <RichText render={body.raw} />}</Grid>
    </>
  );
}

const Grid = styled(Wrapper)`
  max-width: 50vmax;
  margin: 2rem auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    line-height: 1.2;
  }

  p {
    margin-bottom: 1rem;
    line-height: 2;
  }

  ul {
    list-style: initial;
    padding-left: 1rem;

    li {
      margin-bottom: 0.5rem;
    }
  }
`;

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        title
        body {
          raw
        }
      }
    }
  }
`;
