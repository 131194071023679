import React from "react";
import styled from "styled-components";
import { motion as m } from "framer-motion";

/**
 * Local Components
 */
import MotionScroll from "./john-motion/motion-scroll";

/**
 * Local Styles/JS
 */
import { Wrapper } from "./layout-components";
import AnimatedText from "./john-motion/motion-headline";
import { Headline } from "./type";

/**
 * PageHeader Component
 *
 * @param {Object} props
 * @param {String} props.title
 */
export default function PageHeader({ title, children }) {
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <Wrapper>
      <MotionScroll triggerPoint={0} yOffset={50}>
        <PageHeaderWrapper
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 1 }}
        >
          <Headline
            tag="h2"
            size="h1 headline"
            lineLength={0.7}
            aria-label={title}
          >
            <AnimatedText text={title} />
          </Headline>
          {children}
        </PageHeaderWrapper>
      </MotionScroll>
    </Wrapper>
  );
}

const PageHeaderWrapper = styled(m.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: visible;
  padding-top: 5rem;
  padding-bottom: 3rem;
  position: relative;
  z-index: 1;

  .headline {
    line-height: 0.88;
  }
`;
